import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UserRoleService } from '../../services/user-role.service';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermitGuard implements CanActivate {
  permits: Array<any>;
  constructor(private userService: UserRoleService, private router: Router, private cookieService: CookieService){ }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return true;
    return this.userService.getUserPermits(this.cookieService.get(environment.userdata)).toPromise()
    .then(
      (res) => {
        let extras = this.router.getCurrentNavigation().extras;
        this.permits = res['message'];
        switch(route.url[0]['path']){
          case 'users':
            if(this.permits.find(val => val.module === 'users'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('users'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'chat_controler':
            if(this.permits.find(val => val.module === 'chat_control'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('chat_control'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'analytics':
            if(this.permits.find(val => val.module === 'analytics'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('analytics'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'test-information':
            if(this.permits.find(val => val.module === 'test-information'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('test-information'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'permits':
            if(this.permits.find(val => val.module === 'permits'  && val.isAdmin === true))
              return true;
            if(this.permits.find(val => val.module === 'permits'  && val.view === true))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'settings':
            if(this.permits.find(val => val.module === 'settings'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('settings'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'onboarding':
            if(this.permits.find(val => val.module === 'onboardings'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('onboardings'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'chat':
            if(this.permits.find(val => val.module === 'chat'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('chat'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'calendar':
            if(this.permits.find(val => val.module === 'calendar'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('calendar'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'journal':
            if(this.permits.find(val => val.module === 'journals'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('journals'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'notifications':
            if(this.permits.find(val => val.module === 'notifications'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('notifications'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'app_vars':
            if(this.permits.find(val => val.module === 'app_vars'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('app_vars'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'event':
            if(this.permits.find(val => val.module === 'news'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('news'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'capsules':
            if(this.permits.find(val => val.module === 'capsules'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('capsules'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'pathologies':
            if(this.permits.find(val => val.module === 'pathologies'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('pathologies'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'faq':
            if(this.permits.find(val => val.module === 'faqs'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('faqs'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          case 'games':
            if(this.permits.find(val => val.module === 'games_tests'  && val.isAdmin === true))
              return true;
            if(this.reviewCRUDPermits('games_tests'))
              return true;
            this.router.navigate(['/apps/error/403']);
            break;
          //Cases for the CRUD views (create, edit, view)
          case 'create':
            if(state.url.includes('education')){
              let split = state.url.split('/');
              switch(split[3]){
                case 'capsules':
                  if(this.reviewSpecifiedPermit('capsules', 'create')) return true;
                  break;
                case 'pathologies':
                    if(this.reviewSpecifiedPermit('pathologies', 'create')) return true;
                    break;
                case 'faq':
                  if(this.reviewSpecifiedPermit('faqs', 'create')) return true;
                  break;
                case 'games':
                  if(this.reviewSpecifiedPermit('games_tests', 'create')) return true;
                  break;
              }
            }
            if(state.url.includes('users')){
              if(this.reviewSpecifiedPermit('users', 'create')) return true;
            }
            if(state.url.includes('treatments')){
              if(this.reviewSpecifiedPermit('users', 'create')) return true;
            }
            if(state.url.includes('events')){
              if(this.reviewSpecifiedPermit('news', 'create')) return true;
            }
            this.router.navigate(['/apps/error/403']);
            break;
          case 'edit':
              if(state.url.includes('education')){
                let split = state.url.split('/');
                switch(split[3]){
                  case 'capsules':
                    if(this.reviewSpecifiedPermit('capsules', 'edit')) return true;
                    break;
                  case 'pathologies':
                      if(this.reviewSpecifiedPermit('pathologies', 'edit')) return true;
                      break;
                  case 'faq':
                    if(this.reviewSpecifiedPermit('faqs', 'edit')) return true;
                    break;
                  case 'games':
                    if(this.reviewSpecifiedPermit('games_tests', 'edit')) return true;
                    break;
                }
              }
              if(state.url.includes('users')){
                if(this.reviewSpecifiedPermit('users', 'edit')) return true;
              }
              if(state.url.includes('treatments')){
                if(this.reviewSpecifiedPermit('users', 'edit')) return true;
              }
              if(state.url.includes('events')){
                if(this.reviewSpecifiedPermit('news', 'edit')) return true;
              }
              this.router.navigate(['/apps/error/403']);
              break;
          case 'view':
              if(state.url.includes('education')){
                let split = state.url.split('/');
                switch(split[3]){
                  case 'capsules':
                    if(this.reviewSpecifiedPermit('capsules', 'view')) return true;
                    break;
                  case 'pathologies':
                      if(this.reviewSpecifiedPermit('pathologies', 'view')) return true;
                      break;
                  case 'faq':
                    if(this.reviewSpecifiedPermit('faqs', 'view')) return true;
                    break;
                  case 'games':
                    if(this.reviewSpecifiedPermit('games_tests', 'view')) return true;
                    break;
                }
              }
              if(state.url.includes('users')){
                if(this.reviewSpecifiedPermit('users', 'view')) return true;
              }
              if(state.url.includes('treatments')){
                if(this.reviewSpecifiedPermit('users', 'view')) return true;
              }
              if(state.url.includes('events')){
                if(this.reviewSpecifiedPermit('news', 'view')) return true;
              }
              this.router.navigate(['/apps/error/403']);
              break;
        }
        return true;
      }
    )
    .catch(
      (err) => {
        return true;
      }
    )
  }

  reviewCRUDPermits(module){
    if(this.permits.find(val => val.module === module && val.view === true))
      return true;
    if(this.permits.find(val => val.module === module && val.edit === true))
      return true;
    if(this.permits.find(val => val.module === module && val.delete === true))
      return true;
    if(this.permits.find(val => val.module === module && val.create === true))
      return true;
    return false;
  }

  reviewSpecifiedPermit(module, permit){
    switch(permit){
      case 'create':
        if(this.permits.find(val => val.module === module && val.create === true))
          return true;
        break;
      case 'edit':
        if(this.permits.find(val => val.module === module && val.edit === true))
          return true;
        break;
      case 'view':
        if(this.permits.find(val => val.module === module && val.view === true))
          return true;
        break;
    }
    return false;
  }
}
