import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getUsers(user): Observable<any>{
    let options =  new HttpParams();
    options = options.append('user', user);
    return this.http.get(environment.getUsers, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  getUserByID(userID): Observable<any>{
    let options =  new HttpParams();
    options = options.append('id', userID);
    return this.http.get(environment.getUserByID, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  getUserByUserName(username): Observable<any>{
    let options =  new HttpParams();
    options = options.append('user', username);
    return this.http.get(environment.getUserByUsername, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  getRolePermits(role): Observable<any>{
    let options =  new HttpParams();
    options = options.append('roleID', role);
    return this.http.get(environment.getRolePermitsURL, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  getUserPermits(usuario): Observable<any>{
    let options =  new HttpParams();
    options = options.append('user', usuario);
    return this.http.get(environment.getUserPermitsURL, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  getUserByRole(usuario, role): Observable<any>{
    let options =  new HttpParams();
    options = options.append('username', usuario);
    options = options.append('role', role);
    return this.http.get(environment.getUserByRoleURL, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  getAccessibleUsers(): Observable<any>{
    let options =  new HttpParams();
    options = options.append('user', this.cookieService.get(environment.userdata));

    return this.http.get(environment.getAccessibleUsers, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  getUsersByRoleAndCountry(role, country): Observable<any>{
    let options =  new HttpParams();
    options = options.append('country', country);
    options = options.append('role', role);
    return this.http.get(environment.getUserByRoleCountryURL, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  changeUserPassword(data): Observable<any>{
    return this.http.post(environment.changePassword, data).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  forgotUserPassword(data): Observable<any>{
    return this.http.post(environment.forgotPassword, data).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  setUserTempPassword(user): Observable<any>{
    return this.http.get(environment.tempPassword + user).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  getCountries(): Observable<any>{
    return this.http.get(environment.getCountries).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  createCountry(country): Observable<any>{
    return this.http.post(environment.createCountry, country).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  createEvent(event): Observable<any>{
    return this.http.post(environment.createEvent, {event: event}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  getEvents(usuario): Observable<any>{
    let options =  new HttpParams();
    options = options.append('username', usuario);
    return this.http.get(environment.getEvents, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  updateEvent(event, id): Observable<any>{
    return this.http.put(environment.updateEvent + id, event).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  deleteEvent(id): Observable<any>{
    return this.http.delete(environment.deleteEvent + id).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  getRoles(): Observable<any>{
    return this.http.get(environment.getRoles).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  createRole(role): Observable<any>{
    return this.http.post(environment.createRole, role).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  createUser(user): Observable<any>{
    return this.http.post(environment.createUser, user).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  addDoctor(email): Observable<any>{
    return this.http.post(environment.addDoctor, email).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  updateUser(user, id): Observable<any>{
    return this.http.post(environment.updateUser + id, user).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  deleteUser(id): Observable<any>{
    return this.http.post(environment.deleteUser + id, null).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  getUsersPatients(user): Observable<any>{
    let options =  new HttpParams();
    options = options.append('user', user);
    return this.http.get(environment.getUsersPatients, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  getUsersPatientsTotal(user): Observable<any>{
    let options =  new HttpParams();
    options = options.append('user', user);
    return this.http.get(environment.getUsersPatientsTotal, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  updatePermit(permit: String, column: String): Observable<any>{
    return this.http.put(environment.updateRolePermits, {permit: permit, column: column}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  updateTimeZone(timezone: String, user: String): Observable<any>{
    return this.http.put(environment.updateTimezone, {timezone: timezone, user: user}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  createNotification(notification): Observable<any>{
    return this.http.post(environment.createNotification, notification).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  createGlobalEvent(event): Observable<any>{
    return this.http.post(environment.createGlobalEvent, {event: event}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  getGlobalEvents(usuario): Observable<any>{
    let options =  new HttpParams();
    options = options.append('username', usuario);
    return this.http.get(environment.getGlobalEvents, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  updateGlobalEvent(event, id): Observable<any>{
    return this.http.put(environment.updateGlobalEvent + id, event).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  deleteGlobalEvent(id): Observable<any>{
    return this.http.delete(environment.deleteGlobalEvent + id).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

}
