import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from "socket.io-client/dist/socket.io";
import Swal from 'sweetalert2';

import { AngularFirestore } from '@angular/fire/firestore';
import { Message } from 'src/app/message.model';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class ChatSocketService {
  patients = [];
  unreadMessages = [];
  count = 0;
  primeraCorrida: Boolean = true;
  unseen = 0;
  socket: any;
  readonly uri: string = "https://api.emcontrolapp.com";

  constructor(private firestore: AngularFirestore) {
    // this.socket = io.connect(this.uri);
  }

  getUnseen(){
    var total = 0;
    for (let i = 0; i < this.unreadMessages.length; i++) {
      total += this.unreadMessages[i].unread;
    }
    return total > 0;
  }

  setLessUnseen(value){
    this.unseen -= value;
  }

  getChatMessages(value){
    // return this.firestore.collection(value).snapshotChanges();
    return this.firestore.collection(value, ref => ref.orderBy('timestamp', 'desc')).snapshotChanges();
  }

  getUnseenChatMessages(value){
    return this.firestore.collection(value, ref => ref.where('seen', '==', false)).snapshotChanges();
  }

  createMessage(table, message){
    return this.firestore.collection(table).add(message);
  }

  listen(users){
    if(users){
      this.analyzeUsers(users);
      this.unseenMessages(users);
    }
  }

  // privados
  async analyzeUsers(users){
    for (let i = 0; i < users.length; i++) {
      await this.getChatMessages(users[i]._id).subscribe(
        async (data) => {
          var messages = data.map(e => {
            return e.payload.doc.data() as Message;
          });

          this.count++;
          if (this.count > users.length){
            this.primeraCorrida = false;
          }

          if (!this.primeraCorrida) {
            var index = this.lookUser(users[i]._id);
            if(index === -1){
              this.patients.push({
                user: users[i],
                messages: messages
              })
              if(!this.primeraCorrida){
                Swal.fire({ title: 'Tienes un nuevo mensaje', text: '', showConfirmButton: false, timer: 2000, position: 'top-end', toast: true, icon: 'info'});
              }
            }else{
              if(this.patients[index].messages.length < messages.length){
                this.patients[index].messages = messages;
                if(!this.primeraCorrida){
                  Swal.fire({ title: 'Tienes un nuevo mensaje', text: '', showConfirmButton: false, timer: 2000, position: 'top-end', toast: true, icon: 'info'});
                }
              }
            }
          }

        },
        (err) => {
          console.log('Error', err)
        }
      );
    }
  }

  lookUser(id){
    let temp = -1;
    this.patients.forEach(element => {
      if(element.user._id.localeCompare(id) == 0){
        temp = element;
        return;
      }
    });
    if(temp === -1) return -1;
    temp = this.patients.indexOf(temp);
    return temp;
  }

  async unseenMessages(users){
    for (let i = 0; i < users.length; i++) {
      await this.getUnseenChatMessages(users[i]._id).subscribe(
        async (data) => {
          var messages = data.map(e => {
            return e.payload.doc.data() as Message;
          });

          var index = this.lookUser(users[i]._id);
          if(index === -1){
            this.unreadMessages.push({
              _id: users[i]._id,
              unread: messages.length
            });
          }else{
            this.unreadMessages[index].unread = messages.length;
          }
        },
        (err) => {
          console.log('Error', err)
        }
      );
    }
  }

  lookUserUnread(id){
    let temp = -1;
    this.unreadMessages.forEach(element => {
      if(element._id.localeCompare(id) == 0){
        temp = element;
        return;
      }
    });
    if(temp === -1) return -1;
    temp = this.unreadMessages.indexOf(temp);
    return temp;
  }
}
