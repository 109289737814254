import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ChatSocketService } from '././services/chat-socket.service'
import Swal from 'sweetalert2';
import { Message } from './message.model';
import { UserRoleService } from './services/user-role.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'nobleui-angular';

  constructor(private webSocketService: ChatSocketService, private router: Router, private patientService: UserRoleService, private cookieService: CookieService){
    this.patientService.getUsersPatientsTotal( this.cookieService.get(environment.userdata) ).subscribe(
      (res) => {
        this.webSocketService.listen(res.values);
      },
      (error) => {
        console.log('Error');
      }
    )
  }

  ngOnInit(): void { }
}
