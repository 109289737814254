import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  //Treatments
  getTreatments(): Observable<any>{
    let options =  new HttpParams();
    options = options.append('user', this.cookieService.get(environment.userdata));
    return this.http.get(environment.getTreatments, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  getTreatmentById(id): Observable<any>{
    let options =  new HttpParams();
    options = options.append('id', id);
    return this.http.get(environment.getTreatmentByID, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  createTreatment(treatment): Observable<any>{
    let options =  new HttpParams();
    options = options.append('user', this.cookieService.get(environment.userdata));
    return this.http.post(environment.createTreatment, treatment, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  updateTreatment(treatment, id): Observable<any>{
    return this.http.put(environment.updateTreatment + id, treatment).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  deleteTreatment(id): Observable<any>{
    return this.http.put(environment.deleteTreatment + id, null).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }


  //Onboarding
  getUserProgress(user): Observable<any>{
    return this.http.get(environment.getUserProgress + user).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  getUsersProgressByNurse(user): Observable<any>{
    let options =  new HttpParams();
    options = options.append('user', user);
    return this.http.get(environment.getUsersProgressByNurseID, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  getUserProgressByID(progressID): Observable<any>{
    return this.http.get(environment.getUsersProgressByID + progressID).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  createUserProgress(progress): Observable<any>{
    return this.http.post(environment.createUserProgress, progress).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  updateUserProgress(progress, id): Observable<any>{
    return this.http.post(environment.updateUsersProgress + id, progress).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  //Journal
  getUsersForJournal(user): Observable<any>{
    let options =  new HttpParams();
    options = options.append('user', user);
    return this.http.get(environment.getJournalsUsers, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  getUsersJournals(userId): Observable<any>{
    return this.http.get(environment.getAllJournalsFromUser + userId).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  getJournal(userId): Observable<any>{
    return this.http.get(environment.getJournal + userId).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  //Chat
  getUsersByNurse(user): Observable<any>{
    let options =  new HttpParams();
    options = options.append('user', user);
    return this.http.get(environment.getUsersChatsByNurse, {params: options}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  getUsersChat(user): Observable<any>{
    return this.http.get(environment.getUserChat + user).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  sendMessage(message): Observable<any>{
    return this.http.post(environment.sendChatMessage, message).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  encryptMessage(message): Observable<any>{
    return this.http.post(environment.encryptMessage, message).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  decryptMessage(message): Observable<any>{
    return this.http.post(environment.decryptMessage, message).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  decryptMultipleMessages(messages): Observable<any>{
    return this.http.post(environment.decryptMessages, messages).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }


  sendNotification(notification): Observable<any>{
    return this.http.post(environment.sendChatNotification, notification).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  readMessages(user): Observable<any>{
    return this.http.post(environment.readMessages + user, null).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }
}
