import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { PatientService } from './patient.service';
import { UserRoleService } from './user-role.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public chats = [];
  usersIDs = [];

  constructor(private http: HttpClient, private router: Router, private location: Location, private cookieService: CookieService, private patientService: PatientService, private userService: UserRoleService) { }

  /**
   * Metodo de Login que retorna un JWT y lo almacena en LocalStorage/cookie
   *
   * @param {string} username
   * @param {string} passwd
   * @memberof LoginService
   */
  login(username:string, passwd:string): Observable<any>{
    let expiredDate;
    return this.http.post(environment.loginURL, {user: username, pass: passwd}).pipe(
      map(res => {
        let temo = JSON.parse( JSON.stringify(res) );
        expiredDate = new Date(Date.now() + res['expires_in']);
        this.cookieService.set(environment.userdata, String(username), expiredDate, '/', '', false, "Lax");
        this.cookieService.set(environment.tokenID, res['token'], expiredDate, '/', '', false, "Lax");
        this.cookieService.set(environment.expiresInID, res['expires_in'], expiredDate, '/', '', false, "Lax");
        this.cookieService.set(environment.timezone, temo.userTimeZone, expiredDate, '/', '', false, "Lax");
        this.cookieService.set(environment.userRole, temo.userRole, expiredDate, '/', '', false, "Lax");
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }

  requestToken(){
    let expiredDate;
    return this.http.get(environment.checkTokenURL).pipe(
      map(res => {
        expiredDate = new Date(Date.now() + res['expires_in']);
        // console.log('Se ha actualizado el token');
        // console.log(expiredDate);
        this.cookieService.set(environment.userdata, this.cookieService.get(environment.userdata), expiredDate, '/', '', false, "Lax");
        this.cookieService.set(environment.timezone, this.cookieService.get(environment.timezone), expiredDate, '/', '', false, "Lax");
        this.cookieService.set(environment.userRole, this.cookieService.get(environment.userRole), expiredDate, '/', '', false, "Lax");
        this.cookieService.set(environment.tokenID, res['token'], expiredDate, '/', '', false, "Lax");
        this.cookieService.set(environment.expiresInID, res['expires_in'], expiredDate, '/', '', false, "Lax");
        return true;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getToken(){
    return this.cookieService.get(environment.tokenID);
  }

  async verificarToken() {
    let t;
    try {
      t = await this.requestToken().toPromise();
    } catch (error) {
      t = false;
    }
    return t;
  }

  sesionValida(){
    return new Promise((resolve, reject) =>{
      this.verificarToken().then((res) =>{
        // console.log("Token", res);
        if(this.cookieService.check(environment.tokenID) && res){
          resolve(true);
        }
        else{
          resolve(false);
        }
      })
    })
  }

  getUsersToChatService(){
    //Get all users that the loged user can listen
    this.patientService.getUsersByNurse(this.cookieService.get(environment.userdata)).subscribe(
      (res) => {
        this.chats = res.values.active.map((i) => {
          i.lastMessage.date = this.compareDates(new Date(i.lastMessage.timestamp), new Date()) ? this.convertToHour(i.lastMessage.timestamp) : new Date(i.lastMessage.timestamp).toLocaleDateString('es-GT');
          i.lastMessage.hour = new Date();
          this.usersIDs.push(i.user._id);
          return i;
        });

        res.values.filed.map((i) => {
          this.usersIDs.push(i.user._id);
          this.chats.push(i);
        });

        // console.log('Tables to listen:', this.usersIDs);
        environment.loggedUserChats = this.usersIDs;
      },
      (err) => {
        console.log('Error', err);
      }
    );
  }

  compareDates(dateA, dateB){
    if((dateA.getFullYear() == dateB.getFullYear()) && (dateA.getMonth() == dateB.getMonth()) && (dateA.getDate() == dateB.getDate())){
      return true;
    }
    return false;
  }

  convertToHour(timestamp){
    return new Date(timestamp).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  }

  validateRegisterToken(token): Observable<any>{
    return this.http.post(environment.validateDoctorToken, {token: token}).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }

  registerDoctor(requestBody): Observable<any>{
    return this.http.post(environment.registerDoctor, requestBody).pipe(
      map(res => {
        return res;
      }),
      catchError(err => of(err))
    )
  }
}
