import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UserRoleService } from '../../../services/user-role.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

import MetisMenu from 'metismenujs/dist/metismenujs';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { Router, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { elementAt } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {
  permits: Array<any>;
  @ViewChild('sidebarToggler') sidebarToggler: ElementRef;

  menuItems = [];
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;

  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, private router: Router, private userService: UserRoleService, private cookieService: CookieService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
          this.document.body.classList.remove('sidebar-open');
        }

      }
    });
  }

  ngOnInit(): void {
    this.menuItems = MENU;

    this.userService.getUserPermits(this.cookieService.get(environment.userdata)).subscribe(
      (res) => {
        this.permits = res['message'];
        this.validateMenuItems();
      },
      (err) => {
        console.log(err);
      }
    );
    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    const desktopMedium = window.matchMedia('(min-width:992px) and (max-width: 1199px)');
    desktopMedium.addListener(this.iconSidebar);
    this.iconSidebar(desktopMedium);
  }

  reviewCRUDPermits(module){
    if(this.permits.find(val => val.module === module && val.isAdmin === true))
      return true;
    if(this.permits.find(val => val.module === module && val.view === true))
      return true;
    if(this.permits.find(val => val.module === module && val.edit === true))
      return true;
    if(this.permits.find(val => val.module === module && val.delete === true))
      return true;
    if(this.permits.find(val => val.module === module && val.create === true))
      return true;
    return false;
  }

  validateMenuItems(){
    var deleteAppTracking = true, deleteMedicalInformation = true, deleteWebApps = true, deleteAppManagement = true;
    this.permits.forEach(element => {
        switch (element.title) {
          // General Use
          case 'permits':
            if(!this.reviewCRUDPermits('permits')) this.deleteMenuItem('Permisos');
            break;
          case 'users':
            if(!this.reviewCRUDPermits('users')) this.deleteMenuItem('Usuarios');
            break;
          case 'settings':
            if(!this.reviewCRUDPermits('settings')) this.deleteMenuItem('Ajustes');
            break;
          // App Tracking
          case 'chat_control':
            if(!this.reviewCRUDPermits('chat_control')) this.deleteMenuItem('Control de chat');
            break;
          case 'analytics':
            if(!this.reviewCRUDPermits('analytics')) this.deleteMenuItem('Analytics');
            break;
          // Medical Information
          case 'test-information':
            if(!this.reviewCRUDPermits('test-information')) {
              this.deleteMenuItem('Medical Information');
              this.deleteMenuItem('Test Information');
              this.deleteMenuItem('Patients Dashboard');
              this.deleteMenuItem('Medical Education');
              this.deleteMenuItem('Patient Activity');
            }else{
              if(this.cookieService.get(environment.userRole) != '60896fa3dad1854fa4cec3e0'){
                this.deleteMenuItem('Test Information');
                this.deleteMenuItem('Medical Education');
                this.deleteMenuItem('Patient Activity');
              }
            }
            break;
          // Web Apps
          case 'onboardings':
            if(!this.reviewCRUDPermits('onboardings')) this.deleteMenuItem('Onboarding');
            break;
          case 'chat':
            if(!this.reviewCRUDPermits('chat')) this.deleteMenuItem('Chat');
            break;
          case 'calendar':
            if(!this.reviewCRUDPermits('calendar')) this.deleteMenuItem('Calendar');
            break;
          case 'journals':
            if(!this.reviewCRUDPermits('journals')) this.deleteMenuItem('Journal');
            break;
          // App Management
          case 'news':
            if(!this.reviewCRUDPermits('news')) this.deleteMenuItem('Events', 'Events Information');
            break;
          case 'treatments':
            if(!this.reviewCRUDPermits('treatments')) this.deleteMenuItem('Treatments');
            break;
          case 'capsules':
            if(!this.reviewCRUDPermits('capsules')) this.deleteMenuItem('Educational Material', 'Capsules');
            break;
          case 'pathologies':
            if(!this.reviewCRUDPermits('pathologies')) this.deleteMenuItem('Educational Material', 'Pathologies');
            break;
          case 'faqs':
            if(!this.reviewCRUDPermits('faqs')) this.deleteMenuItem('Educational Material', 'FAQ');
            break;
          case 'games_tests':
            if(!this.reviewCRUDPermits('games_tests')) this.deleteMenuItem('Educational Material', 'Games and Test');
            break;
          case 'notifications':
            if(!this.reviewCRUDPermits('notifications')) this.deleteMenuItem('Push Notifications');
            break;
          case 'app_vars':
            if(!this.reviewCRUDPermits('app_vars')) this.deleteMenuItem('Información General');
            break;
          case 'reports':
            // if(!this.reviewCRUDPermits('reports')) this.deleteMenuItem('Onboarding');
            break;
          case 'time_control':
            if(!this.reviewCRUDPermits('time_control')) this.deleteMenuItem('Time Control');
            break;
          default:
            break;
        }
    });

    // Delete app tracking
    for (const element of this.menuItems) {
      if(element.label == 'Chat Control' || element.label == 'Analytics'){
        deleteAppTracking = false;
        break;
      }
    }
    if(deleteAppTracking) this.deleteMenuItem('App Tracking')

    // Delete app management
    for (const element of this.menuItems) {
      if(element.label == 'Educational Material' || element.label == 'Push Notifications' || element.label == 'Treatments' || element.label == 'Events' || element.label == 'General Information'){
        deleteAppManagement = false;
        break;
      }
    }
    if(deleteAppManagement) this.deleteMenuItem('App Management')

    // Delete web apps
    for (const element of this.menuItems) {
      if(element.label == 'Chat' || element.label == 'Calendar' || element.label == 'Journal' || element.label == 'Onboarding'){
        deleteWebApps = false;
        break;
      }
    }
    if(deleteWebApps) this.deleteMenuItem('Web Apps')
  }

  deleteMenuItem(menuItem, subItem = ''){
    var temp, subTemp, isSubelement = false;
    for (const element of this.menuItems) {
      if(element.label == menuItem){
        temp = element;
        if(menuItem == 'Educational Material'){
          for (const subElement of element.subItems) {
            if(subElement.label == subItem){
              subTemp = subElement;
              isSubelement = true;
              break;
            }
          }
        }
        break;
      }
    }

    if (temp) {
      if(isSubelement){
        var index = this.menuItems.indexOf(temp);
        this.menuItems[index].subItems.splice(this.menuItems[index].subItems.indexOf(subTemp), 1);
        if(this.menuItems[index].subItems.length == 0) this.menuItems.splice(this.menuItems.indexOf(temp), 1);
      }else{
        this.menuItems.splice(this.menuItems.indexOf(temp), 1);
      }
    }

  }

  ngAfterViewInit() {
    // activate menu item
    new MetisMenu(this.sidebarMenu.nativeElement);

    this._activateMenuDropdown();
  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e) {
    this.sidebarToggler.nativeElement.classList.toggle('active');
    this.sidebarToggler.nativeElement.classList.toggle('not-active');
    if (window.matchMedia('(min-width: 992px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-folded');
    } else if (window.matchMedia('(max-width: 991px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-open');
    }
  }


  /**
   * Toggle settings-sidebar
   */
  toggleSettingsSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('settings-open');
  }


  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')){
      this.document.body.classList.add("open-sidebar-folded");
    }
  }


  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')){
      this.document.body.classList.remove("open-sidebar-folded");
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(e) {
    if (e.matches) {
      this.document.body.classList.add('sidebar-folded');
    } else {
      this.document.body.classList.remove('sidebar-folded');
    }
  }


  /**
   * Switching sidebar light/dark
   */
  onSidebarThemeChange(event) {
    this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
    this.document.body.classList.add(event.target.value);
    this.document.body.classList.remove('settings-open');
  }


  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }


  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }


  /**
   * Resets the menus
   */
  resetMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
          parentEl.classList.remove('mm-active');
          const parent2El = parentEl.parentElement;

          if (parent2El) {
            parent2El.classList.remove('mm-show');
          }

          const parent3El = parent2El.parentElement;
          if (parent3El) {
            parent3El.classList.remove('mm-active');

            if (parent3El.classList.contains('side-nav-item')) {
              const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

              if (firstAnchor) {
                firstAnchor.classList.remove('mm-active');
              }
            }

            const parent4El = parent3El.parentElement;
            if (parent4El) {
              parent4El.classList.remove('mm-show');

              const parent5El = parent4El.parentElement;
              if (parent5El) {
                parent5El.classList.remove('mm-active');
              }
            }
          }
      }
    }
  };


  /**
   * Toggles the menu items
   */
  activateMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    let menuItemEl = null;

    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
        if (window.location.pathname.includes(links[i]['pathname'])) {

            menuItemEl = links[i];

            break;
        }
    }

    if (menuItemEl) {
        menuItemEl.classList.add('mm-active');
        const parentEl = menuItemEl.parentElement;

        if (parentEl) {
            parentEl.classList.add('mm-active');

            const parent2El = parentEl.parentElement;
            if (parent2El) {
                parent2El.classList.add('mm-show');
            }

            const parent3El = parent2El.parentElement;
            if (parent3El) {
                parent3El.classList.add('mm-active');

                if (parent3El.classList.contains('side-nav-item')) {
                    const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

                    if (firstAnchor) {
                        firstAnchor.classList.add('mm-active');
                    }
                }

                const parent4El = parent3El.parentElement;
                if (parent4El) {
                    parent4El.classList.add('mm-show');

                    const parent5El = parent4El.parentElement;
                    if (parent5El) {
                        parent5El.classList.add('mm-active');
                    }
                }
            }
        }
    }
  };


}
