import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Main',
    isTitle: true
  },
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/dashboard'
  },
  // {
  //   label: 'Users',
  //   icon: 'users',
  //   link: '/apps/users',
  // },
  // {
  //   label: 'Permits',
  //   icon: 'check-square',
  //   link: '/apps/permits',
  // },
  // {
  //   label: 'Settings',
  //   icon: 'settings',
  //   link: '/apps/settings',
  // },
  {
    label: 'Usuarios',
    icon: 'users',
    link: '/apps/users'
  },
  {
    label: 'Permisos',
    icon: 'check-square',
    link: '/apps/permits'
  },
  {
    label: 'Ajustes',
    icon: 'settings',
    link: '/apps/settings',
  },
  {
    label: 'App Tracking',
    isTitle: true
  },
  {
    label: 'Control de chat',
    icon: 'monitor',
    link: '/apps/chat_controler'
  },
  {
    label: 'Analytics',
    icon: 'trending-up',
    link: '/apps/analytics'
  },
  {
    label: 'Time Control',
    icon: 'clock',
    link: '/apps/time_control'
  },
  {
    label: 'Medical Information',
    isTitle: true
  },
  {
    label: 'Patients Dashboard',
    icon: 'bar-chart',
    link: '/apps/medical-dashboard'
  },
  {
    label: 'Medical Education',
    icon: 'award',
    link: '/apps/medical-education'
  },
  {
    label: 'Patient Activity',
    icon: 'airplay',
    link: '/apps/test-information'
  },
  {
    label: 'Web Apps',
    isTitle: true
  },
  {
    label: 'Onboarding',
    icon: 'file-text',
    link: '/apps/onboarding',
  },
  {
    label: 'Chat',
    icon: 'message-square',
    link: '/apps/chat',
  },
  {
    label: 'Calendar',
    icon: 'calendar',
    link: '/apps/calendar',
  },
  {
    label: 'Journal',
    icon: 'book',
    link: '/apps/journal',
  },
  {
    label: 'App Management',
    isTitle: true
  },
  {
    label: 'Events',
    icon: 'file-text',
    subItems: [
      {
        label: 'Banner',
        link: '/apps/events/banner',
      },
      {
        label: 'Events Information',
        link: '/apps/events',
      },
    ],
  },
  {
    label: 'Educational Material',
    icon: 'book-open',
    subItems: [
      {
        label: 'Capsules',
        link: '/apps/education/capsules',
      },
      {
        label: 'Pathologies',
        link: '/apps/education/pathologies',
      },
      {
        label: 'FAQ',
        link: '/apps/education/faq',
      },
      {
        label: 'Games and Test',
        link: '/apps/education/games',
      },
    ]
  },
  {
    label: 'Treatments',
    icon: 'clipboard',
    link: '/apps/treatment',
  },
  {
    label: 'Push Notifications',
    icon: 'bell',
    link: '/apps/notifications',
  },
  {
    label: 'Información General',
    icon: 'smartphone',
    link: '/apps/app_vars',
  },

];
