import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { UserRoleService } from 'src/app/services/user-role.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { ChatSocketService } from 'src/app/services/chat-socket.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  service;
  loggedUser = '';
  doctorDashboard: Boolean = false;
  nurseAdminDashboard: Boolean = false;
  welcome_country:String = "";
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private cookieService: CookieService,
    private userService: UserRoleService,
    private webSocketService: ChatSocketService
  ) {
    switch (this.cookieService.get(environment.userRole)) {
      case '6088786071045016241f8d91': // admin
        this.doctorDashboard = false;
        this.nurseAdminDashboard = true;
        break;
      case '632b4224e75699001a308e78': // psp specialist
        this.doctorDashboard = false;
        this.nurseAdminDashboard = true;
        break;
      case '608878ec71045016241f8da2': // nurse
        this.doctorDashboard = false;
        this.nurseAdminDashboard = true;
        break;
      case '60896fa3dad1854fa4cec3e0': // doctor
        this.doctorDashboard = true;
        this.nurseAdminDashboard = false;
        break;
      default:
        this.doctorDashboard = false;
        this.nurseAdminDashboard = false;
        break;
    }

    switch(this.cookieService.get(environment.timezone)){
      case 'America/Guatemala':
        this.welcome_country = "CANDEAN";
        break;
      case 'America/Dominica':
        this.welcome_country = "CANDEAN";
        break;
      case 'America/Panama':
        this.welcome_country = "CANDEAN";
        break;
      case 'America/Costa_Rica':
        this.welcome_country = "CANDEAN";
        break;
      case 'America/Santiago':
        this.welcome_country = "Chile";
        break;
      case 'America/Lima':
        this.welcome_country = "CANDEAN";
        break;
      case 'America/Guayaquil':
        this.welcome_country = "CANDEAN";
        break;
      default:
        this.welcome_country = "";
        break;
    }

  }

  ngOnInit(): void {
    this.userService.getUserByUserName(this.cookieService.get(environment.userdata)).subscribe(
      (res) => {
        this.loggedUser = res.values.firstName + ' ' + res.values.lastName;
      },
      (err) => {
        // console.log(err);
      }
    )

    this.service = this.webSocketService;
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    this.cookieService.delete(environment.tokenID, '/', '', false, "Lax");
    this.cookieService.delete(environment.expiresInID, '/', '', false, "Lax");
    this.cookieService.delete(environment.timezone, '/', '', false, "Lax");
    this.cookieService.delete(environment.userdata, '/', '', false, "Lax");
    this.cookieService.deleteAll('/', '', false, "Lax");
    this.router.navigate(['/auth/login']);
  }

}
