import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authentication: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authentication.sesionValida().then(res => {
      if(res){
        return true;
      }
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
      // this.router.navigate(['pages/login'], {state: {data: {exit: true}, navigationId: 25}});
      return false;
    })
  }
}
